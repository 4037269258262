import React from "react"
import { DynamicComponent } from "./DynamicComponent"
import { sbEditable } from "storyblok"

const Page = ({ story }) => {
  const { content } = story
  const contentBody =
    content.body &&
    content.body.map(childBlok => (
      <DynamicComponent blok={childBlok} key={childBlok._uid} />
    ))
  const hasTitle =
    content.title && content.title.length ? (
      <h1 className="text-5xl font-bold font-serif text-primary tracking-wide text-center py-8">
        {content.title}
      </h1>
    ) : null

  return (
    <div {...sbEditable(content)}>
      {hasTitle}
      {contentBody}
    </div>
  )
}

export default Page
