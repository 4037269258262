import React from "react"
import Page from "../components/Page"
import { BlogPost } from "../components/BlogPost"
import Layout from "../components/Layout"
import { useStoryblok } from "../storyblok"

const contentComponents = {
  Page,
  BlogPost,
}

export default function Page404({ location }) {
  const story = useStoryblok(null)

  const Component = contentComponents[story?.content?.component]

  if (Component) {
    return (
      <Layout location={location}>
        <Component story={story} />
      </Layout>
    )
  }

  return (
    <Layout location={location}>
      <h1>Not found</h1>
    </Layout>
  )
}
